import Welcome from "../sections/Welcome";
import SheetHeads from "../sections/SheetHeads";
import InfoBlocks from "../sections/InfoBlocks";
import Quests from "../sections/Quests";
import Prizes from "../sections/Prizes";
import FooterBanner from "../sections/FooterBanner";

function Home() {
  return (
    <>
      <Welcome />
      <SheetHeads />
      <InfoBlocks />
      <Quests />
      <Prizes />
      <FooterBanner />
    </>
  );
}

export default Home;
