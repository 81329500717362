import styled from "styled-components";
import { SCREEN_SIZES, COLORS } from "../styles";
import ContentWrapper from "../components/shared/ContentWrapper";
import liquidity from "../assets/liquidity.gif";
import jumping from "../assets/jumping.gif";
import {
  HomeContent,
  HomeTextBlock,
  HomeTitle,
} from "../components/shared/Text";
import { Button } from "../components/shared/Button";
import { logEvent } from "../utils/analytics";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    display: block;
  }
`;

const TextBlock = styled.div`
  padding: 115px 70px 20px 70px;
  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
  }
`;

const LiquidityTextBlock = styled(TextBlock)`
  padding-bottom: 0;
`;

const Block = styled.div`
  width: 50%;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    width: 100%;
  }
`;

const JumpingImage = styled.img`
  width: 85%;
  position: absolute;
  bottom: -5%;
  right: 0;

  @media only screen and (max-width: ${SCREEN_SIZES.desktopL}px) {
    right: -8%;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    position: relative;
    left: 58%;
    transform: translate(-50%, 30%);
    margin-top: -30%;
    width: 60%;
  }
`;

export default function InfoBlocks() {
  return (
    <ContentWrapper>
      <Container>
        <Block style={{ backgroundColor: COLORS.ligthBlue }}>
          <LiquidityTextBlock>
            <HomeTextBlock>
              <HomeTitle>Liquidity</HomeTitle>
              <HomeContent>
                By utilizing Pooled NFT, Sheet Heads unlocks fractionalization
                and instant liquidity to all holders, allowing holders to buy or
                sell into the Uniswap pairs at any time. Liquidity providers
                also earn passive income on pairs.
                <br />
                <br />
                For each NFT added to the pool, users receive an equal number of
                ERC-20 Pool Tokens that represent their ownership. Check out the
                Sheet Heads pool here.
              </HomeContent>
              <a
                target="_blank"
                href="https://poolednft.com/pool/0xc2bc2320D22D47D1e197E99D4a5dD3261ccf4A68"
                rel="noreferrer"
                style={{
                  textDecoration: "none",
                }}
              >
                <Button
                  style={{ position: "relative", zIndex: 200 }}
                  primary
                  onClick={() => logEvent("click_view_pool")}
                >
                  View Pool
                </Button>
              </a>
            </HomeTextBlock>
          </LiquidityTextBlock>
          <img
            style={{ width: "100%", marginTop: "-10%" }}
            src={liquidity}
            alt="liquidity"
          />
        </Block>
        <Block style={{ backgroundColor: COLORS.lightYellow }}>
          <TextBlock>
            <HomeTextBlock>
              <HomeTitle>Sheety-Media</HomeTitle>
              <HomeContent>
                Memes are the center of the Sheet Heads community. Browse or
                create positive Sheet Heads memes for the community to enjoy.
                <br />
                <br />
                View our ever-expanding collection GIFs and memes to use on any
                occasion, or utilize our Sheet Heads Meme Toolkit to craft your
                own content!
              </HomeContent>
              <a
                href="/media"
                style={{
                  textDecoration: "none",
                  zIndex: 2000,
                  position: "relative",
                }}
              >
                <Button secondary onClick={() => logEvent("click_view_media")}>
                  View Media
                </Button>
              </a>
            </HomeTextBlock>
          </TextBlock>
          <JumpingImage src={jumping} />
        </Block>
      </Container>
    </ContentWrapper>
  );
}
