import styled from "styled-components";
import { SCREEN_SIZES, COLORS } from "../styles";
import ContentWrapper from "../components/shared/ContentWrapper";
import prizeImg from "../assets/prize.gif";
import {
  HomeContent,
  HomeTextBlock,
  HomeTitle,
} from "../components/shared/Text";
import { Button } from "../components/shared/Button";
import { XIcon } from "../components/shared/Icons";
import { logEvent } from "../utils/analytics";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${COLORS.background};
  position: relative;
  overflow: hidden;
`;

const Block = styled.div`
  width: 50%;
  min-height: 525px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: ${SCREEN_SIZES.desktopL}px) {
    min-height: 475px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    width: 100%;
    min-height: 0;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    background-image: url(${prizeImg});
    background-size: 45%;
    background-repeat: no-repeat;
    background-position-y: 135%;
    padding-bottom: 35%;
    background-position-x: 80%;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}px) {
    background-position-y: 130%;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobileL}px) {
    background-image: url(${prizeImg});
    background-position-y: 118%;
  }
`;

const ImageBlock = styled.div`
  width: 50%;
  min-height: 525px;

  @media only screen and (max-width: ${SCREEN_SIZES.desktopL}px) {
    min-height: 475px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    display: none;
  }
`;

const BgImage = styled.img`
  width: 595px;
  position: absolute;
  bottom: -34%;
  right: -6%;

  @media only screen and (max-width: ${SCREEN_SIZES.desktopL}px) {
    width: 500px;
  }
`;

const TextBlock = styled.div`
  padding: 0 24px 0 100px;
  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    margin: auto;
    padding: 30px 0;
  }
`;

export default function Prizes() {
  return (
    <ContentWrapper>
      <Container>
        <Block>
          <TextBlock>
            <HomeTextBlock>
              <HomeTitle>Prizes</HomeTitle>
              <HomeContent>
                We pride ourselves for hilarious giveaways like a Year&apos;s
                Supply of KFC, or the Rare, Discontinued Nerf Elite Titan CS-50.
                <br />
                <br />
                As our community grows, these giveaways become more exciting,
                ridiculous, and downright insane. Winners and prizes are
                announced on Twitter, so don&apos;t miss out!
              </HomeContent>
              <a
                target="_blank"
                href="https://x.com/sheet_heads"
                rel="noreferrer"
                style={{
                  textDecoration: "none",
                }}
              >
                <Button tertiary onClick={() => logEvent("click_follow_x")}>
                  Follow us on <XIcon />
                </Button>
              </a>
            </HomeTextBlock>
          </TextBlock>
        </Block>
        <ImageBlock>
          <BgImage src={prizeImg} />
        </ImageBlock>
      </Container>
    </ContentWrapper>
  );
}
