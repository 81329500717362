import styled from "styled-components";
import { Button } from "../components/shared/Button";
import { SCREEN_SIZES } from "../styles";
import ContentWrapper from "../components/shared/ContentWrapper";
import questImg from "../assets/quest.gif";
import { HomeContent, HomeTitle } from "../components/shared/Text";
import { logEvent } from "../utils/analytics";

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 33%;
  background-image: url(${questImg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: bottom;
`;

const TextBlock = styled.div`
  max-width: 985px;
  text-align: center;
  padding: 115px 70px 0px 70px;
  margin-bottom: -45px;
  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    margin: 0 auto -45px auto;
    max-width: 525px;
    padding: 30px 0;
    text-align: left;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.tablet}px) {
    max-width: 475px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.mobileL}px) {
    max-width: 325px;
    margin-bottom: -30px;
  }
`;

const QuestButton = styled(Button)`
  margin: auto;

  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    margin: 0;
  }
`;

export default function Quests() {
  return (
    <ContentWrapper>
      <Container>
        <TextBlock>
          <HomeTitle>Quests</HomeTitle>
          <HomeContent>
            Complete quests and earn XP. Experience earned will act as raffle
            tickets for our exciting giveaways. The more quests you claim, the
            higher your chance to win! No purchase necessary, see official
            rules. Questing is open for everyone to join!
          </HomeContent>
          <a
            target="_blank"
            href="https://zealy.io/cw/hififinance"
            rel="noreferrer"
            style={{
              textDecoration: "none",
            }}
          >
            <QuestButton onClick={() => logEvent("click_start_questing")}>
              Start Questing
            </QuestButton>
          </a>
        </TextBlock>
      </Container>
    </ContentWrapper>
  );
}
