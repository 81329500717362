import styled from "styled-components";
import { SCREEN_SIZES } from "../../styles";

const ContentWrapper = styled.div`
  width: 100%;
  max-width: ${SCREEN_SIZES.desktopXL}px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;

  padding-left: ${(p) => (p.gutters ? "24px" : "0")};
  padding-right: ${(p) => (p.gutters ? "24px" : "0")};
`;

export default ContentWrapper;
