import styled from "styled-components";
import mediaBg from "../assets/media-bg.png";
import mediaBg2 from "../assets/media-bg2.png";
import ContentWrapper from "../components/shared/ContentWrapper";
import MediaDialog from "../components/shared/MediaDialog";
import { COLORS, SCREEN_SIZES } from "../styles";
import {
  Heading,
  HomeContent,
  HomeTitle,
  Subheading,
} from "../components/shared/Text";
import { useState } from "react";
import { Button } from "../components/shared/Button";
import { ExpandIcon, GifsIcon, MemesIcon } from "../components/shared/Icons";
import { useFirebaseStorage } from "../hooks/useFirebaseStorage";
import downloadImage from "../utils/downloadImage";
import { logEvent } from "../utils/analytics";

const Container = styled.div`
  background-image: url(${mediaBg});
  background-repeat: no-repeat;
  background-size: 90%;
  background-position-y: 90px;
  background-position-x: 40px;
  padding-top: 330px;

  @media only screen and (max-width: ${SCREEN_SIZES.desktopL}px) {
    padding-top: 310px;
    background-position-x: 30px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    padding-top: 270px;
    background-position-x: 20px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.tablet}px) {
    padding-top: 260px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.mobileL}px) {
    background-position-y: 65px;
    background-position-x: 15px;
    padding-top: 245px;
  }
`;

const TextHeading = styled.div`
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
`;

const Marker = styled.span`
  color: ${COLORS.tertiary};
`;

const ContentContainer = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background-color: ${(p) =>
    p.yellow ? COLORS.lightYellow : COLORS.background};
`;

const BgImage = styled.img`
  width: 95%;
  position: absolute;
  top: -3%;
  left: 1%;

  @media only screen and (max-width: ${SCREEN_SIZES.mobileL}px) {
    top: -80px;
  }
`;

const Switcher = styled.div`
  width: 700px;
  display: flex;
  position: absolute;
  border-radius: 8px;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #dedede;
  padding: 2px;
  z-index: 1;
  background-color: white;

  @media only screen and (max-width: ${SCREEN_SIZES.desktopL}px) {
    width: 500px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    width: 450px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.tablet}px) {
    width: 450px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.mobileL}px) {
    width: 275px;
  }
`;

const SwitcherTab = styled.div`
  border-radius: ${(p) => (p.active ? "8px" : 0)};
  width: 50%;
  white-space: nowrap;
  padding: 27px 0;
  text-align: center;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: ${SCREEN_SIZES.desktopL}px) {
    padding: 20px 0;
    font-size: 22px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    padding: 15px 0;
    font-size: 20px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.tablet}px) {
    padding: 10px 0;
    font-size: 18px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.mobileL}px) {
    padding: 8px 0;
    font-size: 12px;
  }
`;

const MemeTab = styled(SwitcherTab)`
  background-color: ${(p) => (p.active ? COLORS.buttonSecondary : "white")};
`;

const GifsTab = styled(SwitcherTab)`
  background-color: ${(p) => (p.active ? COLORS.buttonTertiary : "white")};
  color: ${(p) => (p.active ? "white" : "black")};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
  gap: 15px;
  width: 100%;
  justify-content: center;

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}px) {
    grid-template-columns: repeat(auto-fill, 200px);
  }
  @media only screen and (max-width: ${SCREEN_SIZES.mobileL}px) {
    grid-template-columns: repeat(auto-fill, 150px);
  }
`;

// Define the GridItem component
const GridItemBase = styled.div`
  background-image: ${(p) => (p.url ? `url(${p.url})` : "none")};
  background-color: #f0f0f0;
  background-size: cover;
  background-position: center;
  aspect-ratio: 1 / 1;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MediaDescription = styled(Subheading)`
  padding-top: 7%;
  padding-bottom: 4%;
  color: black;
  margin: 0;

  @media only screen and (max-width: ${SCREEN_SIZES.mobileL}px) {
    padding-top: 12%;
    padding-bottom: 6%;
  }
`;

const GridContainer = styled.div`
  max-width: 1050px;
  width: 100%;
`;

const MoreButton = styled(Button)`
  margin: 55px 0;
  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    margin: 44px 0;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.tablet}px) {
    margin: 44px 0;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.mobileL}px) {
    margin: 33px 0;
  }
`;

const ExpandButton = styled(Button)`
  background-color: white;
  color: black;
  box-shadow: none !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &:hover {
    background-color: white;
  }
`;

const MemeMakerContainer = styled.div`
  border: 1px solid ${COLORS.quinary};
  max-width: 79.375rem;
  border-radius: 1rem;
  border: 0.0625rem solid ${COLORS.quinary};
  margin-left: auto;
  margin-right: auto;
  margin-top: 6rem;
  padding: 100px 100px 100px 130px;
  display: flex;
  box-sizing: border-box;
  align-items: center;

  @media only screen and (max-width: ${SCREEN_SIZES.desktopL}px) {
    flex-direction: column;
    justify-content: center;
    max-width: 40.375rem;
    padding: 50px 20px 50px 20px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobileL}px) {
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }
`;

const MemeMakerMeme = styled.div`
  position: relative;
  background-image: ${(p) =>
    !!p.url
      ? `url(${p.url})`
      : "url(https://storage.googleapis.com/public-sheetheads/images-white-bg/0.png)"};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  aspect-ratio: 1 / 1;
  border: 15px solid ${COLORS.ligthBlue};
  border-radius: 15px;
  margin-right: 65px;

  min-width: 350px;
  @media only screen and (max-width: ${SCREEN_SIZES.desktopL}px) {
    margin-right: 0px;
    margin-bottom: 50px;
    min-width: 250px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobileL}px) {
    margin-bottom: 30px;
  }

  &::before {
    display: ${(p) => (!!p.url ? "none" : "block")};
    content: "";
    opacity: 0.9;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; /* Ensure the pseudo-element is behind the content */
  }
`;

const MemeMakerContent = styled.div`
  min-width: 350px;
  @media only screen and (max-width: ${SCREEN_SIZES.desktopL}px) {
    min-width: auto;
  }
`;

const MemeMakerTitle = styled(HomeTitle)`
  @media only screen and (max-width: ${SCREEN_SIZES.desktopL}px) {
    text-align: center;
  }
`;

const MemeMakerInputContainer = styled.div`
  border: 1px solid ${COLORS.quinary};
  height: 65px;
  border-radius: 10px;
  width: 100%;
  display: flex;
  padding: 5px 10px 5px 15px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: ${SCREEN_SIZES.desktopL}px) {
    text-align: center;
    padding: 5px 10px;
    height: auto;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobileS}px) {
    width: fit-content;
  }
`;

const MemeMakerInput = styled.input`
  border: none;
  outline: none;
  height: 100%;
  font-size: 20px;
  margin-right: 15px;
  flex-grow: 1;

  @media only screen and (max-width: ${SCREEN_SIZES.desktopL}px) {
    font-size: 16px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}px) {
    font-size: 14px;
    width: 75px;
  }
`;

const GridItem = (props) => {
  const [showExpand, setShowExpand] = useState(false);
  return (
    <GridItemBase
      onMouseEnter={() => setShowExpand(true)}
      onMouseLeave={() => setShowExpand(false)}
      url={props.url}
      onClick={props.onClick}
    >
      {props.children}
      {showExpand && !!props.url && (
        <ExpandButton>
          <ExpandIcon />
          Expand
        </ExpandButton>
      )}
    </GridItemBase>
  );
};

function Media() {
  const [tabState, setTabState] = useState("memes");
  const [selectedMedia, setSelectedMedia] = useState(null);

  const { data: memes, loading: memesLoading } = useFirebaseStorage("/memes");
  const { data: gifs, loading: gifsLoading } =
    useFirebaseStorage("/gifs/thumbs");

  const [input, setInput] = useState(false);
  const onChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setInput(value);
  };

  const isValid = typeof input === "number" && input >= 0 && input <= 8887;

  const makeMemeUrl = `https://storage.googleapis.com/public-sheetheads/images-no-bg/${input}.png`;

  return (
    <>
      <ContentWrapper>
        <Container>
          <TextHeading>
            <Heading>
              Sheet Heads <Marker>Media</Marker>
            </Heading>

            <Subheading>
              Home to our arsenal of community content. Need to post a funny
              meme? or a hilarious GIF? You’ve come to the right place.
            </Subheading>
          </TextHeading>
          <ContentContainer yellow={tabState === "memes"}>
            <BgImage src={mediaBg2} />
            <Switcher>
              <MemeTab
                onClick={() => setTabState("memes")}
                active={tabState === "memes"}
              >
                <MemesIcon />
                Sheety Memes
              </MemeTab>
              <GifsTab
                onClick={() => setTabState("gifs")}
                active={tabState === "gifs"}
              >
                <GifsIcon white={tabState === "gifs"} />
                Sheety GIFs
              </GifsTab>
            </Switcher>

            {tabState === "memes" && (
              <MediaDescription>
                Memes are the center of the Sheet Heads community. Browse or
                create positive Sheet Heads memes for the community to use.
              </MediaDescription>
            )}
            {tabState === "gifs" && (
              <MediaDescription>
                GIFs, the universal language of the internet! Browse our
                collection of reactionary GIFs that can be used all across the
                crazy world of crypto.
              </MediaDescription>
            )}

            <GridContainer>
              {tabState === "memes" && memesLoading && (
                <Grid>
                  <GridItem>Loading</GridItem>
                  <GridItem>Loading</GridItem>
                  <GridItem>Loading</GridItem>
                  <GridItem>Loading</GridItem>
                  <GridItem>Loading</GridItem>
                  <GridItem>Loading</GridItem>
                  <GridItem>Loading</GridItem>
                  <GridItem>Loading</GridItem>
                  <GridItem>Loading</GridItem>
                  <GridItem>Loading</GridItem>
                  <GridItem>Loading</GridItem>
                  <GridItem>Loading</GridItem>
                </Grid>
              )}
              {tabState === "memes" && !memesLoading && (
                <Grid>
                  {memes.map((data) => {
                    const { url } = data;
                    return (
                      <GridItem
                        onClick={() => setSelectedMedia(data)}
                        url={url}
                        key={url}
                      />
                    );
                  })}
                </Grid>
              )}
              {tabState === "gifs" && gifsLoading && (
                <Grid>
                  <GridItem>Loading</GridItem>
                  <GridItem>Loading</GridItem>
                  <GridItem>Loading</GridItem>
                  <GridItem>Loading</GridItem>
                  <GridItem>Loading</GridItem>
                  <GridItem>Loading</GridItem>
                  <GridItem>Loading</GridItem>
                  <GridItem>Loading</GridItem>
                  <GridItem>Loading</GridItem>
                  <GridItem>Loading</GridItem>
                  <GridItem>Loading</GridItem>
                  <GridItem>Loading</GridItem>
                </Grid>
              )}
              {tabState === "gifs" && !memesLoading && (
                <Grid>
                  {gifs.map((data) => {
                    const { url } = data;
                    return (
                      <GridItem
                        onClick={() => setSelectedMedia(data)}
                        url={url}
                        key={url}
                      />
                    );
                  })}
                </Grid>
              )}
            </GridContainer>

            {tabState === "memes" && (
              <a
                target="_blank"
                href="https://memedepot.com/d/sheety-memes"
                rel="noreferrer"
                style={{
                  textDecoration: "none",
                }}
              >
                <MoreButton onClick={() => logEvent("click_more_memes")}>
                  More Memes
                </MoreButton>
              </a>
            )}
            {tabState === "gifs" && (
              <a
                target="_blank"
                href="https://giphy.com/channel/Sheet_Heads"
                rel="noreferrer"
                style={{
                  textDecoration: "none",
                }}
              >
                <MoreButton onClick={() => logEvent("click_more_gifs")}>
                  More GIFs
                </MoreButton>
              </a>
            )}
          </ContentContainer>
        </Container>
      </ContentWrapper>
      <ContentWrapper gutters>
        <MemeMakerContainer>
          <MemeMakerMeme url={isValid ? makeMemeUrl : ""} />
          <MemeMakerContent>
            <MemeMakerTitle>Make Your Own Memes!</MemeMakerTitle>
            <HomeContent>
              Use this tool when creating your own Sheet Head memes to share
              with the world!
              <br />
              <br />
              Enter the ID of your desired Sheet Head below and click download.
              This easy-to-edit version of the Sheet Head makes meme generation
              quick and painless.
            </HomeContent>
            <MemeMakerInputContainer>
              <MemeMakerInput
                onChange={onChange}
                placeholder="Enter Sheet Head ID"
                type="text"
              />
              <Button
                disabled={!isValid}
                flat
                onClick={() => {
                  downloadImage(makeMemeUrl);
                  logEvent("click_download_meme");
                }}
              >
                Download
              </Button>
            </MemeMakerInputContainer>
          </MemeMakerContent>
        </MemeMakerContainer>
      </ContentWrapper>
      {!!selectedMedia && (
        <MediaDialog
          data={selectedMedia}
          onClose={() => setSelectedMedia(null)}
          isOpen
        />
      )}
    </>
  );
}

export default Media;
