import React, { useEffect, useState } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import downloadImage from "../../utils/downloadImage";
import styled from "styled-components";
import { Button } from "./Button";
import { DownloadIcon } from "./Icons";
import { logEvent } from "../../utils/analytics";

// Overlay component
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
`;

// Dialog wrapper
const DialogWrapper = styled.div`
  background: white;
  width: 95vw;
  max-width: 754px;
  aspect-ratio: 1 / 1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

// Dialog header
const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

// Close button
const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 25px;
  cursor: pointer;
`;

// Content area
const DialogContent = styled.div`
  padding: 20px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.div`
  background-image: ${(p) => `url(${p.url})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`;

const MediaDialog = ({ onClose, data }) => {
  const [fullImgUrl, setFullImgUrl] = useState("");
  const { url, name } = data;
  const isGif = name.includes("gif");
  const type = isGif
    ? "gif"
    : name.toLowerCase().endsWith("jpg")
    ? "jpg"
    : "png";

  useEffect(() => {
    const setUrl = async () => {
      if (!isGif) {
        setFullImgUrl(url);
      } else {
        const storage = getStorage();
        const fullSizeName = name.replace("_250x250", "");
        const fullSizeRef = ref(storage, `gifs/${fullSizeName}`);
        try {
          const fullSizeUrl = await getDownloadURL(fullSizeRef);
          setFullImgUrl(fullSizeUrl);
        } catch (error) {
          console.error("Error fetching full-size image URL:", error);
        }
      }
    };
    setUrl();
  }, [name, url, isGif]);

  return (
    <Overlay>
      <DialogWrapper>
        <DialogHeader>
          <Button
            black
            flat
            onClick={() => {
              downloadImage(fullImgUrl, `sheethead.${type}`);
              if (isGif) {
                logEvent("click_dialog_download_gif");
              } else {
                logEvent("click_dialog_download_meme");
              }
            }}
          >
            <DownloadIcon />
            Download {isGif ? "GIF" : "Meme"}
          </Button>
          <CloseButton onClick={onClose}>&#x2715;</CloseButton>
        </DialogHeader>
        <DialogContent>
          <Image url={fullImgUrl} />
        </DialogContent>
      </DialogWrapper>
    </Overlay>
  );
};

export default MediaDialog;
