import styled from "styled-components";
import { SCREEN_SIZES, COLORS } from "../../styles";

export const Button = styled.div`
  font-size: 16px;
  font-weight: 600;
  padding: 12px 25px;
  border-radius: 6px;
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  transition: all 0.3s ease;
  text-decoration: none;


  cursor: ${(p) => {
    if (p.disabled) return "not-allowed";
    return "pointer";
  }};

  background-color: ${(p) => {
    if (p.disabled) return COLORS.buttonDisabled;
    if (p.primary) return COLORS.buttonPrimary;
    if (p.secondary) return COLORS.buttonSecondary;
    if (p.tertiary) return COLORS.buttonTertiary;
    if (p.black) return "black";
    return COLORS.buttonPrimary; // Default color if none is specified
  }};

  color: ${(p) => {
    if (p.disabled) return COLORS.secondary;
    if (p.primary || p.tertiary) return "white";
    if (p.secondary) return "black";
    return "white"; // Default text color if none is specified
  }};

  box-shadow: ${(p) => {
    if (p.flat || p.disabled) return "none";
    if (p.primary) return `8px 6px 0 rgba(24, 94, 230, 0.2)`;
    if (p.secondary) return `8px 6px 0 rgba(248, 188, 26, 0.2)`;
    if (p.tertiary) return `8px 6px 0 rgba(37, 159, 49, 0.2)`;
    return `8px 6px 0 rgba(24, 94, 230, 0.2)`; // Default shadow if none is specified
  }};

  &:hover {
    background-color: ${(p) => {
      if (p.disabled) return COLORS.buttonDisabled;
      if (p.primary) return "#104bb3";
      if (p.secondary) return "#d9a215";
      if (p.tertiary) return "#1e8a2b";
      if (p.black) return "rgba(0, 0, 0, .7)";
      return "#104bb3"; // Default hover color if none is specified
    }};
  }

  &:active {
    background-color: ${(p) => {
      if (p.disabled) return COLORS.buttonDisabled;
      if (p.primary) return "#0d3a8a";
      if (p.secondary) return "#b88f13";
      if (p.tertiary) return "#196f26";
      return "#0d3a8a"; // Default active color if none is specified
    }};
    box-shadow: ${(p) => {
      if (p.flat || p.disabled) return "none";
      if (p.primary) return `8px 6px 0 rgba(13, 58, 138, 0.2)`;
      if (p.secondary) return `8px 6px 0 rgba(184, 143, 19, 0.2)`;
      if (p.tertiary) return `8px 6px 0 rgba(25, 111, 38, 0.2)`;
      return `8px 6px 0 rgba(13, 58, 138, 0.2)`; // Default active shadow if none is specified
    }};
  }

  &:focus {
    outline: none;
    box-shadow: ${(p) => {
      if (p.flat || p.disabled) return "none";
      if (p.primary) return `8px 6px 0 rgba(24, 94, 230, 0.7)`;
      if (p.secondary) return `8px 6px 0 rgba(248, 188, 26, 0.7)`;
      if (p.tertiary) return `8px 6px 0 rgba(37, 159, 49, 0.7)`;
      return `8px 6px 0 rgba(24, 94, 230, 0.7)`; // Default focus shadow if none is specified
    }};
  }

  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    padding: 10px 20px;
    font-size: 14px;
  }
`;
