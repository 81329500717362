import styled from "styled-components";
import { COLORS, MEDIA_SIZES } from "../styles";
import ContentWrapper from "../components/shared/ContentWrapper";
import logo from "../assets/logo.svg";
import discordLogo from "../assets/discord-logo.svg";
import twitterLogo from "../assets/twitter-logo.svg";
import openseaLogo from "../assets/opensea-logo.svg";
import { logEvent } from "../utils/analytics";

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Links = styled.div`
  position: relative;
  margin: 0 auto;
  height: 2.75rem;
  margin-top: 7.5625rem;
  width: 90%;
  max-width: 71.4375rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 3.25rem;

  @media only screen and (${MEDIA_SIZES.max.tablet}) {
    margin-top: 3.3125rem;
  }
`;

const Logo = styled.a`
  width: 10.375rem;
  height: 2.7rem;
  flex-shrink: 0;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  left: 0;
`;

const DiscordLink = styled.a`
  display: block;
  background-image: url(${discordLogo});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  right: 0;
  top: 0.675rem;
`;

const TwitterLink = styled.a`
  display: block;
  background-image: url(${twitterLogo});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  right: 2.75rem;
  top: 0.675rem;
`;

const OpenseaLink = styled.a`
  background-image: url(${openseaLogo});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  right: 5.5rem;
  top: 0.675rem;
`;

const Line = styled.div`
  position: absolute;
  background-color: ${COLORS.quinary};
  height: 0.0625rem;
  width: 100%;
  margin: 0 auto;
  bottom: 0;
`;

const FooterText = styled.div`
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: 1.5rem;
  margin-top: 2.5625rem;
  margin-bottom: 1.75rem;
  width: 90%;
  max-width: 71.4375rem;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (${MEDIA_SIZES.max.tablet}) {
    margin-top: 3.3125rem;
    margin-bottom: 6.75rem;
  }
`;

const CopyrightText = styled.div`
  position: absolute;
  color: ${COLORS.secondary};

  @media only screen and (${MEDIA_SIZES.max.tablet}) {
    left: 0;
  }
`;

const DisclaimerText = styled.div`
  position: absolute;
  color: ${COLORS.secondary};
  right: 0;
  @media only screen and (${MEDIA_SIZES.max.tablet}) {
    margin-top: 3.3125rem;
    margin-bottom: 3.75rem;
    left: 0;
  }
`;

function Footer() {
  return (
    <ContentWrapper gutters>
      <Container>
        <Links>
          <Logo href="/" />
          <DiscordLink
            href="https://discord.gg/PRVfJQbJZ8"
            target="_blank"
            onClick={() => {
              logEvent("click_discord_footer");
            }}
          />
          <TwitterLink
            href="https://twitter.com/sheet_heads"
            target="_blank"
            onClick={() => {
              logEvent("click_twitter_footer");
            }}
          />
          <OpenseaLink
            href="https://opensea.io/collection/sheet-heads"
            target="_blank"
            onClick={() => {
              logEvent("click_opensea_footer");
            }}
          />
          <Line />
        </Links>
        <FooterText>
          <CopyrightText>
            ©Sheet Heads by{" "}
            <a
              href="https://hifi.finance"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              Hifi Finance
            </a>
          </CopyrightText>
          <DisclaimerText>
            Sheet Heads is not affiliated with Google or Alphabet
          </DisclaimerText>
        </FooterText>
      </Container>
    </ContentWrapper>
  );
}

export default Footer;
