import styled from "styled-components";
import { COLORS, SCREEN_SIZES } from "../styles";
import marker1 from "../assets/marker-1.svg";
import ContentWrapper from "../components/shared/ContentWrapper";
import heroes from "../assets/heroes.png";
import heroBackground from "../assets/hero-background.png";
import { Heading, Subheading } from "../components/shared/Text";

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  background-image: url(${heroBackground});
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 100px;
  background-position: bottom;
  aspect-ratio: 1728 / 804;
  
  @media only screen and (max-width: ${SCREEN_SIZES.desktopL}px) {
    padding-top: 95px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    padding-top: 80px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.tablet}px) {
    padding-top: 150px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.mobileL}px) {
    padding-top: 160px;
  }
`;

const Marker = styled.span`
  color: ${COLORS.tertiary};
  background-image: url(${marker1});
  background-repeat: no-repeat;
  background-size: 7.75rem;
  background-position: center;
  width: 100%;
  height: 100%;
`;

const TextHeading = styled.div`
  position: absolute;
  top: 115px;
  left: 50%;
  transform: translateX(-50%);
  @media only screen and (max-width: ${SCREEN_SIZES.desktopL}px) {
    top: 90px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    top: 75px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.tablet}px) {
    top: 70px;
  }
`;

const Heroes = styled.img`
  position: absolute;
  bottom: -120px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 70%;

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}px) {
    bottom: 20px;
  }
`;

function Welcome() {
  return (
    <ContentWrapper>
      <Container>
        <TextHeading>
          <Heading>
            Welcome to the <Marker>Sheety</Marker>-verse!
          </Heading>
          <Subheading>
            Sheet Heads, a collection of 8,888 unique characters built in Google
            Sheets, living on the Ethereum blockchain.
          </Subheading>
        </TextHeading>
        <Heroes src={heroes} />
      </Container>
    </ContentWrapper>
  );
}

export default Welcome;
