export const SCREEN_SIZES = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 576,
  tablet: 768,
  desktopS: 992,
  desktopL: 1200,
  desktopXL: 1728,
};

export const footerHeight = "30.375rem";
export const sideSpacing = {
  max: "3rem",
  xxxlarge: "2.75rem",
  xxlarge: "2.675",
  xlarge: "2.5rem",
  large: "2.475rem",
  medium: "2.35rem",
};
export const contentWidthMin = "12.5rem";
export const maxWidthMax = "120rem";
export const hamburgerBarHeight = 0.25;
export const hamburgerBarSpacing = 0.4;

export const MEDIA_SIZES = {
  min: {
    phoneS: `min-width: ${SCREEN_SIZES.mobileS}px`,
    phoneM: `min-width: ${SCREEN_SIZES.mobileM}px`,
    phoneL: `min-width: ${SCREEN_SIZES.mobileL}px`,
    tablet: `min-width: ${SCREEN_SIZES.tablet}px`,
    desktopS: `min-width: ${SCREEN_SIZES.desktopS}px`,
    desktopL: `min-width: ${SCREEN_SIZES.desktopL}px`,
    desktopXL: `min-width: ${SCREEN_SIZES.desktopXL}px`,
  },
  max: {
    phoneS: `max-width: ${SCREEN_SIZES.mobileS}px`,
    phoneM: `max-width: ${SCREEN_SIZES.mobileM}px`,
    phoneL: `max-width: ${SCREEN_SIZES.mobileL}px`,
    tablet: `max-width: ${SCREEN_SIZES.tablet}px`,
    desktopS: `max-width: ${SCREEN_SIZES.desktopS}px`,
    desktopL: `max-width: ${SCREEN_SIZES.desktopL}px`,
    desktopXL: `max-width: ${SCREEN_SIZES.desktopXL}px`,
  },
};

export const COLORS = {
  primary: "#000000",
  secondary: "#5F6368",
  tertiary: "#259F31;",
  quaternary: "#1F1F47",
  quinary: "#E4E4E7",
  buttonPrimary: "#185ee6",
  buttonSecondary: "#F8BC1A",
  buttonSecondary2: "#0e3cb6",
  buttonTertiary: "#259F31",
  buttonDisabled: "#a1a1a1",
  background: "#EEF7EE",
  lightYellow: "#FCEBB3",
  ligthBlue: "#DCE7FB",
};
