import styled from "styled-components";
import { SCREEN_SIZES } from "../styles";
import ContentWrapper from "../components/shared/ContentWrapper";
import yellowImg from "../assets/dancing-duck.gif";
import {
  HomeContent,
  HomeTextBlock,
  HomeTitle,
} from "../components/shared/Text";
import { Button } from "../components/shared/Button";
import { logEvent } from "../utils/analytics";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TextBlock = styled.div`
  padding: 0 24px 0 30px;
  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    margin: auto;
    padding: 30px 0;
  }
`;

const Block = styled.div`
  width: 50%;
  min-height: 525px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: ${SCREEN_SIZES.desktopL}px) {
    min-height: 475px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    width: 100%;
    min-height: 0;

    background-image: url(${yellowImg});
    background-size: 55%;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 245px;
    padding-bottom: 150px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}px) {
    background-position-y: 300px;
    padding-bottom: 150px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobileL}px) {
    background-position-y: 280px;
    padding-bottom: 90px;
  }
`;

const ImageBlock = styled.div`
  width: 50%;
  min-height: 525px;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: ${SCREEN_SIZES.desktopL}px) {
    min-height: 475px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    display: none;
  }
`;

const BgImage = styled.img`
  width: 864px;
  aspect-ratio: 864 / 890;
  position: absolute;
  bottom: -400px;
  left: -300px;

  @media only screen and (max-width: ${SCREEN_SIZES.desktopL}px) {
    width: 600px;
    bottom: -200px;
    left: -150px;
  }
`;

export default function SheetHeads() {
  return (
    <ContentWrapper>
      <Container>
        <ImageBlock>
          <BgImage src={yellowImg} />
        </ImageBlock>
        <Block>
          <TextBlock>
            <HomeTextBlock>
              <HomeTitle>Sheet Heads</HomeTitle>
              <HomeContent>
                An NFT collection with experimentation at its core. Sheet Heads
                are purpose-built for innovation and fun memes. Proudly part of
                the Hifi Ecosystem, the Sheet Head brand produces Content,
                launches experimental products, and pushes the boundaries of
                what is possible with digital collectibles on the blockchain.
              </HomeContent>
              <a
                target="_blank"
                href="https://opensea.io/collection/sheet-heads"
                rel="noreferrer"
                style={{
                  textDecoration: "none",
                }}
              >
                <Button
                  secondary
                  onClick={() => logEvent("click_view_collection")}
                >
                  View Collection
                </Button>
              </a>
            </HomeTextBlock>
          </TextBlock>
        </Block>
      </Container>
    </ContentWrapper>
  );
}
