import styled from "styled-components";
import ContentWrapper from "../components/shared/ContentWrapper";
import { DiscordIcon } from "../components/shared/Icons";
import { Button } from "../components/shared/Button";
import { COLORS, MEDIA_SIZES, SCREEN_SIZES } from "../styles";
import kissy from "../assets/kissy.gif";
import bubble from "../assets/bubble.gif";
import shooting from "../assets/shooting-star.png";
import marker from "../assets/marker-1.svg";
import { logEvent } from "../utils/analytics";

const Banner = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 79.375rem;
  border-radius: 1rem;
  border: 0.0625rem solid ${COLORS.quinary};
  margin-left: auto;
  margin-right: auto;
  margin-top: 6rem;
  @media only screen and (max-width: ${SCREEN_SIZES.desktopL}px) {
    background-size: 32.25rem;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    background-size: 27.25rem;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.tablet}px) {
    background-size: 21.25rem;
  }

  min-height: 34.5rem;
  @media only screen and (${MEDIA_SIZES.max.tablet}) {
    min-height: 30rem;
  }
  @media only screen and (${MEDIA_SIZES.max.phoneL}) {
    min-height: 28rem;
  }
`;

const Marker = styled.span`
  background-image: url(${marker});
  background-repeat: no-repeat;
  background-size: 7.75rem;
  background-position: center;
  width: 100%;
  height: 100%;
`;

const FooterBlock = styled.div`
  margin-top: 11.4375rem;
  margin-left: 6.4375rem;
  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    margin-top: 6.375rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
  }
`;

const Heading = styled.div`
  font-size: 3.125rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.125rem;
  margin-bottom: 3.125rem;
  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    margin-bottom: 3.125rem;
    text-align: center;
  }
  @media only screen and (${MEDIA_SIZES.max.tablet}) {
    font-size: 2.125rem;
  }
`;

const LineBreak = styled.br`
  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    display: none;
  }
`;

const KissyImage = styled.img`
  position: absolute;
  width: 50%;
  top: 16%;
  right: -12%;
  transform: rotate(-40deg);

  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    transform: rotate(0deg);
    top: auto;
    bottom: -30%;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}px) {
    bottom: -20%;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobileL}px) {
    bottom: -6%;
  }
`;

const BubbleImage = styled.img`
  position: absolute;
  width: 18%;
  bottom: 0;
  left: 30%;
`;

const ShootingImage = styled.img`
  position: absolute;
  width: 18%;
  top: 16%;
  left: 40%;

  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    left: 4%;
    top: 40%;
  }
`;

const DiscordButton = styled.a`
  display: block;

  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

function FooterBanner() {
  return (
    <ContentWrapper gutters>
      <Banner>
        <FooterBlock>
          <Heading>
            Shoot the {""}
            <LineBreak />
            <Marker>sheet</Marker> with us
          </Heading>
          <DiscordButton
            target="_blank"
            href="https://discord.gg/PRVfJQbJZ8"
            rel="noreferrer"
            style={{
              textDecoration: "none",
            }}
          >
            <Button>
              Discord
              <DiscordIcon
                onClick={() => {
                  logEvent("click_shoot_the_sheet_discord_button");
                }}
              />
            </Button>
          </DiscordButton>
        </FooterBlock>
        <KissyImage src={kissy} />
        <BubbleImage src={bubble} />
        <ShootingImage src={shooting} />
      </Banner>
    </ContentWrapper>
  );
}

export default FooterBanner;
