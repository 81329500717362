import { useState, useEffect } from "react";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";

export const useFirebaseStorage = (folderPath) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const storage = getStorage();
        const listRef = ref(storage, folderPath);
        const res = await listAll(listRef);

        // Sort the items by their names
        const sortedItems = res.items.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        const itemsWithUrls = await Promise.all(
          sortedItems.map(async (itemRef) => {
            const url = await getDownloadURL(itemRef);
            return { url, name: itemRef.name };
          })
        );

        setData(itemsWithUrls);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [folderPath]);

  return { data, loading, error };
};
