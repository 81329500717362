import { getAnalytics, logEvent as _logEvent } from "firebase/analytics";
import { firebaseApp } from "../App";

let analytics;
export function init() {
  try {
    if (!analytics) {
      analytics = getAnalytics(firebaseApp);
    }
  } catch (error) {
    console.error("Error initializing Firebase Analytics:", error);
  }
}

export function logEvent(name, properties) {
  init();
  try {
    if (typeof properties === "object") {
      // Cleansing properties
      const cleansedProperties = Object.keys(properties).reduce((acc, key) => {
        const value = properties[key];
        acc[key] =
          typeof value === "string" && value.startsWith("0x")
            ? value.substr(2)
            : value;
        return acc;
      }, {});

      _logEvent(analytics, name, cleansedProperties);
    } else {
      _logEvent(analytics, name);
    }
  } catch (error) {
    console.error("Error logging Firebase event:", error);
  }
}
