import styled from "styled-components";
import { SCREEN_SIZES, COLORS } from "../../styles";

export const HomeTitle = styled.div`
  font-size: 36px;
  padding-bottom: 0.5em;

  @media only screen and (max-width: ${SCREEN_SIZES.desktopL}px) {
    font-size: 32px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    font-size: 25px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.tablet}px) {
    font-size: 24px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.mobileL}px) {
    font-size: 22px;
  }
`;

export const HomeContent = styled.div`
  font-size: 20px;
  line-height: 1.5em;
  padding-bottom: 1.5em;

  @media only screen and (max-width: ${SCREEN_SIZES.desktopL}px) {
    font-size: 18px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    font-size: 17px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.tablet}px) {
    font-size: 16px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.mobileL}px) {
    font-size: 14px;
  }
`;

export const HomeTextBlock = styled.div`
  max-width: 625px;
  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    max-width: 525px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.tablet}px) {
    max-width: 475px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.mobileL}px) {
    max-width: 325px;
  }
`;

export const Heading = styled.div`
  font-size: 2.75rem;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;

  @media only screen and (max-width: ${SCREEN_SIZES.desktopL}px) {
    font-size: 2.25rem;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    font-size: 2rem;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.mobileM}px) {
    font-size: 1.9rem;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.mobileS}px) {
    font-size: 1.75rem;
  }
`;

export const Subheading = styled.div`
  font-size: 1.15rem;
  font-weight: 400;
  text-align: center;
  color: ${COLORS.secondary};
  max-width: 650px;
  margin: auto;
  margin-top: 1em;

  @media only screen and (max-width: ${SCREEN_SIZES.desktopL}px) {
    font-size: 1.15rem;
    max-width: 500px;
    // border-color: blue;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.desktopS}px) {
    font-size: 1rem;
    max-width: 400px;
    // border-color: orange;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.tablet}px) {
    // border-color: green;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.mobileL}px) {
    // border-color: purple;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.mobileM}px) {
    // border-color: black;
  }
`;
