import styled from "styled-components";
import logo from "../assets/logo.svg";
import discordLogo from "../assets/discord-logo.svg";
import twitterLogo from "../assets/twitter-logo.svg";
import openseaLogo from "../assets/opensea-logo.svg";
import ContentWrapper from "../components/shared/ContentWrapper";
import { logEvent } from "../utils/analytics";

const Container = styled(ContentWrapper)`
  position: absolute;
  height: 2.25rem;
  top: 1.375rem;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

const Logo = styled.a`
  width: 10.375rem;
  height: 2.7rem;
  flex-shrink: 0;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

const DiscordLink = styled.a`
  display: block;
  background-image: url(${discordLogo});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  z-index: 1;
`;

const TwitterLink = styled.a`
  display: block;
  background-image: url(${twitterLogo});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  z-index: 1;
`;

const OpenseaLink = styled.a`
  background-image: url(${openseaLogo});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
`;

const Links = styled.div`
  display: flex;

  & * + * {
    margin-left: 15px;
  }
`;

function Navbar() {
  return (
    <Container gutters>
      <Logo href="/" />
      <Links>
        <DiscordLink
          href="https://discord.gg/PRVfJQbJZ8"
          target="_blank"
          onClick={() => {
            logEvent("click_discord_navbar");
          }}
        />
        <TwitterLink
          href="https://twitter.com/sheet_heads"
          target="_blank"
          onClick={() => {
            logEvent("click_twitter_navbar");
          }}
        />
        <OpenseaLink
          href="https://opensea.io/collection/sheet-heads"
          target="_blank"
          onClick={() => {
            logEvent("click_opensea_navbar");
          }}
        />
      </Links>
    </Container>
  );
}

export default Navbar;
