const downloadImage = async (url, fileName) => {
  try {
    const response = await fetch(url, { mode: "cors" });
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);

    const element = document.createElement("a");
    element.href = blobUrl;
    element.download = fileName || url.split("/").pop(); // Use the provided file name or default to the URL file name
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error("Failed to download image", error);
  }
};

export default downloadImage;
