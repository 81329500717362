import Navbar from "./sections/Navbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { styled } from "styled-components";
import Footer from "./sections/Footer";
import { initializeApp } from "firebase/app";
import Home from "./pages/Home";
import Media from "./pages/Media";

const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const firebaseApp = initializeApp(firebaseConfig);
// firebase.analytics();

function App() {
  return (
    <Router>
      <Container>
        <Navbar href="/" />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/media" element={<Media />} />
        </Routes>
        <Footer />
      </Container>
    </Router>
  );
}

export default App;